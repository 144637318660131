
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: {NgongVegBanner},
  setup(props) {
    const form = ref({});

    const order_numbers = ref();

    const dailySummation = ref();

    const agent = ref();

    const orders = ref([]);

    const assignments = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const agents = ref([]);
    const agent_id = ref(0);

    const customers = ref([]);
    const user_id = ref(0);
    const customer = computed(() => {
      return (
        customers.value?.find((c: any) => c.id == user_id.value) || {
          contact: { currency: "euro" }
        }
      );
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const depart = ref(new Date());

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }
    const start = ref(friday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function sumKgs(oldObject: any) {
      let sum = 0;

      for (const key in oldObject) {
        if (oldObject[key] !== undefined) {
          sum += parseFloat(oldObject[key]);
        }
      }
      return sum;
    }

    function sumWeight(date: any, weight: any, count: any, qty: any) {
      // console.log(date, weight, count, parseFloat(qty));
      // dailySummation.value[date]["boxes"] = parseFloat(dailySummation.value[date]["boxes"]) + parseFloat(qty); // summation
      Object.entries(dailySummation.value).forEach((key: any, index: any) => {
        if (key[0] === date) {
          // console.log(date, weight, count, parseFloat(qty), "wertyu");
        }
      });
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then(res => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/users?role=customer&fetch=all`).then(res => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    async function fetchOrders(user: any) {
      const res = await http.get(
        `/api/orders?user_id=${user}&start=${fD(
          start.value,
          "yyyy-MM-dd"
        )}&end=${fD(end.value, "yyyy-MM-dd")}`
      );
      orders.value = res.data.data;
      return res.data.data;
    }

    function fetchAssignments(id: any) {
      processing.value = true;

      fetchOrders(id).then((os: any) => {
        http.get(`/api/assignments?user_id=${id}&fetch=all`).then(res => {
          const a = res.data;
          assignments.value = a;
          const dates = getDates(start.value);

          form.value = dates.reduce((o, d) => {
            const order: any = os.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, p: any) => ({
                  ...r,
                  [p.id]: order?.quantities
                    ? (order?.quantities as any)[p.id]
                    : 0
                }),
                {}
              )
            };
          }, {});

          dailySummation.value = dates.reduce((o, d) => {
            const order: any = os.find((or: any) => or.date == d);
            // console.log(order)
            return {
              ...o,
              [d]: order?.totals
            };
          }, {});

          agent.value = dates.reduce((o, d) => {
            const order: any = os.find((or: any) => or.date == d);
            return {
              ...o,
              [d]: order?.totals?.boxes === 0 ? "" : order?.agent?.name
            };
          }, {});

          order_numbers.value = dates.reduce((o, d) => {
            const order: any = os.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: order?.number
            };
          }, {});

          processing.value = false;
        });
      });
    }

    function searchAssignments() {
      fetchOrders(user_id.value).then((os: any) => {
        http
          .get(`/api/assignments?user_id=${user_id.value}&s=${search.value}`)
          .then(res => {
            const a = res.data.data;
            assignments.value = a;

            const dates = getDates(start.value);

            form.value = dates.reduce((o, d) => {
              const order: any = os.find((or: any) => or.date == d);

              return {
                ...o,
                [d]: a.reduce(
                  (r: any, p: any) => ({
                    ...r,
                    [p.id]: order?.quantities
                      ? (order?.quantities as any)[p.id]
                      : 0
                  }),
                  {}
                )
              };
            }, {});
          });
      });
    }

    function confirmOrders() {
      http
        .post("/api/orders", {
          user_id: user_id.value,
          orders: form.value,
          start: start.value,
          end: end.value,
          depart: depart.value,
          confirm: true
        })
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          fetchAssignments(user_id.value);
        });
    }

    function updateAssignment(assignment: any) {
      assignment._method = "PUT";
      http
        .post(`/api/assignments/${assignment.id}`, assignment)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchAssignments(user_id.value);
        });
    }

    function deleteAssignment(id: any) {
      http.post(`/api/assignments/${id}`, { _method: "DELETE" }).then(res => {
        fetchAssignments(user_id.value);
      });
    }

    function getPackageSum(section = "count") {
      const data = Object.values(form.value);

      return data.reduce((o: any, n: any) => {
        return o + parseFloat(n[section]);
      }, 0);
    }

    function currencySymbol() {
      if (customer.value?.contact?.currency == "euro") {
        return "€";
      } else if (customer.value?.contact?.currency == "pound") {
        return "£";
      } else if (customer.value?.contact?.currency == "dollar") {
        return "$";
      } else if (customer.value?.contact?.currency == "shilling") {
        return "KSh";
      }
    }

    onMounted(() => {
      fetchAgents();
      fetchCustomers();

      if (router.currentRoute.value.query?.user_id) {
        const u = router.currentRoute.value.query?.user_id;

        let d = router.currentRoute.value.query?.date;

        user_id.value = Number(u);
        d = String(d);
        start.value = new Date(d);
        fetchAssignments(u);
      }
    });

    watch(
      () => start.value,
      (v: any) => {
        fetchAssignments(user_id.value);
      }
    );

    function updateForm() {
      fetchAssignments(user_id.value);
    }

    return {
      fetchAssignments,
      processing,
      search,
      searchAssignments,
      assignments,
      confirmOrders,
      created,
      assignment,
      updateAssignment,
      updated,
      deleteAssignment,
      fD,
      getDates,
      start,
      end,
      form,
      customers,
      customer,
      user_id,
      updateForm,
      getPackageSum,
      currencySymbol,
      depart,
      order_numbers,
      sumKgs,
      sumWeight,
      dailySummation,
      agent,
      orders
    };
  }
});
